import React from 'react'
import { navigate } from 'gatsby'

const COOKIE_POLICY_DISMISSED = 'cookiePolicyDismissed'

class IrisDropdownBanner extends React.Component {
  state = {
    closed: false,
  }

  closeNotification = () => {
    this.setState({ closed: true })
    localStorage.setItem(COOKIE_POLICY_DISMISSED, 'true')
  }

  redirectAndClose = () => {
    this.closeNotification()
    navigate('/cookies-policy')
  }

  render() {
    const { width } = this.props

    const shouldDisplayMobile = width < 1020 ? 'notification-mobile' : ''

    const { closed } = this.state

    return (
      <div className={`notification-banner ${shouldDisplayMobile}`}>
        {!closed && (
          <div className="notification-inner-content">
            <div className="body-content">
              {shouldDisplayMobile ? (
                <p>
                  By browsing you agree to our{' '}
                  <a onClick={this.redirectAndClose}>Cookie Policy</a>
                </p>
              ) : (
                <p>
                  We use cookies to offer you a better browsing experience,
                  analyze site traffic, personalize content, and serve targeted
                  advertisements. If you want to know more or withdraw your
                  consent to all or some of the cookies, please refer to the{' '}
                  <a onClick={this.redirectAndClose}>
                    Cookies and Tracking Policy
                  </a>
                  . By closing this banner, clicking a link, or continuing to
                  browse otherwise, you agree to the use of cookies.
                </p>
              )}
            </div>
            <svg
              fill="#616161"
              className="close-icon"
              onClick={this.closeNotification}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        )}
      </div>
    )
  }
}

const CookiePolicyPopup = ({ width }) => {
  const dismissed = localStorage.getItem(COOKIE_POLICY_DISMISSED) === 'true'
  if (dismissed) {
    return null
  }
  return <IrisDropdownBanner width={width} />
}

export default CookiePolicyPopup
