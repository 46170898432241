export const ViewTypes = {
  stackedWide: 'ivr-stacked-wide',
  stackedNarrow: 'ivr-stacked-narrow',
  rowWide: 'ivr-row-wide',
  rowNarrow: 'ivr-row-narrow',
}

export const getViewType = widthClass => {
  const isStackedNarrow = widthClass === ViewTypes.stackedNarrow
  const isStackedWide = widthClass === ViewTypes.stackedWide
  const isRowNarrow = widthClass === ViewTypes.rowNarrow
  const isRowWide = widthClass === ViewTypes.rowWide
  return { isStackedNarrow, isStackedWide, isRowNarrow, isRowWide }
}

export default {
  [ViewTypes.stackedNarrow]: 580,
  [ViewTypes.stackedWide]: 1100,
  [ViewTypes.rowNarrow]: 1230,
  [ViewTypes.rowWide]: Infinity,
}
