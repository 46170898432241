/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import CookiePolicyPopup from './CookiePolicyPopup'
import Autodesk10112023 from './banners/Autodesk10112023'



import './GlobalNotifications.global.scss'

class GlobalNotifications extends React.Component {
  render() {
    const { width } = this.props

    return (
      <div className="global-notifications">
        <Autodesk10112023 width={width} />
        <CookiePolicyPopup width={width} />
      </div>
    )
  }
}

GlobalNotifications.propTypes = {
  width: PropTypes.number.isRequired,
}

export default GlobalNotifications
