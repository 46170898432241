export const metadata = [
  {
    title: 'VR That Is Easy To Use',
    position: 'left',
    description:
      "Load your 3D file and click launch—that's it. Our VR menu is built for presentations, collaborative sessions, and design review.",
    hasCta: false,
    hasPreface: true,
  },
  {
    title: 'Immersive Collaboration',
    position: 'right',
    description:
      'Walk through your 3D files with colleagues in VR, no matter their location. Multiuser provides reliable voice chat and a shared virtual environment for presentations and design reviews.',
    hasCta: true,
    hasPreface: false,
  },
  {
    title: 'Native 3D File Support',
    position: 'left',
    description:
      'We offer full automatic conversion from Navisworks, Revit, Rhino, FBX, and more. Materials, metadata, and views from these formats transfer seamlessly into the VR experience.',
    hasCta: false,
    hasPreface: false,
  },
  {
    title: 'Engineered For Comfort',
    position: 'right',
    description:
      'Comfort is king. We optimize for low latency, high frame rates, and easy navigation in VR. Our software delivers the best experience for complex projects.',
    hasCta: false,
    hasPreface: false,
  },
]
