import React from 'react'
import { Link } from 'gatsby'

const IntegrationPageLink = props => (
  <Link to={props.to} style={{ color: 'white', textDecoration: 'none' }}>
    {props.integrationName}
  </Link>
)

const NavisworksLink = () => (
  <IntegrationPageLink
    to="/integrations/navisworks"
    integrationName={'Navisworks'}
  />
)
const RevitLink = () => (
  <IntegrationPageLink to="/integrations/revit" integrationName={'Revit'} />
)
const RhinoLink = () => (
  <IntegrationPageLink to="/integrations/rhino" integrationName={'Rhino'} />
)
const SketchUpLink = () => (
  <IntegrationPageLink
    to="/integrations/sketchup"
    integrationName={'SketchUp'}
  />
)

export default [
  {
    name: '3DModel',
    title: '3D MODEL',
    description: {
      standard: (
        <span>
          <NavisworksLink /> | <RevitLink /> | <RhinoLink /> | <SketchUpLink />{' '}
          | FBX | OBJ
        </span>
      ),
      mobile: (
        <span>
          <NavisworksLink />
          <span> | </span>
          <RevitLink />
          <span> | </span>
          <RhinoLink />
          <br />
          <SketchUpLink />
          <span> | FBX | OBJ</span>
        </span>
      ),
    },
  },
  {
    name: 'DesktopSetup',
    title: 'DESKTOP SETUP',
    description: {
      standard: 'Windows 7+ | Mac Not Supported',
      mobile: (
        <span>
          Windows 7+
          <br />
          Mac Not Supported
        </span>
      ),
    },
  },
  {
    name: 'HMD',
    title: 'HEAD MOUNTED DISPLAY',
    description: {
      standard: 'Oculus Rift | Meta Quest 2 | HTC Vive | Windows MR',
      mobile: (
        <span>
          Oculus Rift
          <br />
          Meta Quest 2
          <br />
          HTC Vive
          <br />
          Windows MR
        </span>
      ),
    },
  },
]
