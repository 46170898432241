import React from 'react'
import PropTypes from 'prop-types'
import { IrisCTALinkButton } from '../../../Shared'

const Value = ({ title, description, position, hasPreface, cta, style }) => (
  <div className={`value-container ${position}`} style={style}>
    <div className="value">
      {hasPreface && (
        <div className="preface-container">
          <p className="preface">Why IrisVR</p>
        </div>
      )}
      <div className="title-container">
        <p className="value-title">{title}</p>
      </div>
      <div className="desc-container">
        <p className="value-desc">{description}</p>
      </div>
      {cta && (
        <div style={{ paddingTop: 25 }}>
          <IrisCTALinkButton
            href={process.env.GASTSBY_IRIS_SIGNUP_URL}
            cta={'Try VR Now'}
          />
        </div>
      )}
    </div>
  </div>
)

Value.defaultProps = {
  cta: undefined,
}

Value.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  hasPreface: PropTypes.bool.isRequired,
  hasCta: PropTypes.bool.isRequired,
  cta: PropTypes.func,
}

export default Value
