import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const IrisImg = ({
  file: {
    childImageSharp: { fixed, fluid },
  },
  ...passThroughProps
}) => {
  const imgProps = fixed ? { fixed } : { fluid }
  const theProps = {
    ...imgProps,
    ...passThroughProps,
    loading: 'eager',
    fadeIn: false,
  }
  return <Img {...theProps} />
}

IrisImg.propTypes = {
  file: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fixed: PropTypes.shape(),
      fluid: PropTypes.shape(),
    }),
  }).isRequired,
}

export default IrisImg
