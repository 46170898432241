import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg } from '../../../../Shared'

const Logo = ({ alt, url, file, title }) => (
  <div className={`logo logo-${title}`}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <IrisImg alt={alt} file={file} />
    </a>
  </div>
)

Logo.propTypes = {
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  file: PropTypes.shape().isRequired,
}

export default Logo
