import React from 'react'
import Logo from './Logo'
import { metadata } from './metadata'

const FeaturedOn = ({ files }) => (
  <div className="featured-on-container">
    <div className="featured-on">
      <p className="header">Featured On</p>
      <div className="logos-container">
        {Object.keys(metadata)
          .map(key => [key, metadata[key]])
          .map(([key, { alt, url }]) => (
            <Logo key={key} title={key} alt={alt} url={url} file={files[key]} />
          ))}
      </div>
    </div>
  </div>
)

export default FeaturedOn
