import React from 'react'
import PropTypes from 'prop-types'

class Mp4Video extends React.PureComponent {
  static propTypes = {
    poster: PropTypes.shape().isRequired,
    video: PropTypes.shape().isRequired,
  }

  render() {
    const { poster, video, ...passThroughProps } = this.props
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        poster={poster}
        {...passThroughProps}
      >
        <source type="video/mp4" src={video} />
      </video>
    )
  }
}

export default Mp4Video
