import React from 'react'
import Popper from '@material-ui/core/Popper/Popper'
import Grow from '@material-ui/core/Grow/Grow'
import Paper from '@material-ui/core/Paper/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener'

class DropdownPopper extends React.PureComponent {
  render() {
    const { children, open, anchorEl, handleClose } = this.props

    return (
      <div style={{ display: 'flex' }}>
        <div>
          <Popper
            placement="bottom-start"
            open={open}
            anchorEl={anchorEl}
            transition
            disablePortal
            style={{ zIndex: 3, marginTop: '10px' }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper square>
                  <ClickAwayListener onClickAway={handleClose}>
                    {children}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    )
  }
}

export default DropdownPopper
