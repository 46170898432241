export const requirementsCopy = [
  {
    category: 'OS',
    copy: 'Windows 8 SP1 64 bit or newer',
  },
  {
    category: 'Headset',
    copy: {
      welcome: 'Oculus Rift, Meta Quest 2, HTC Vive, or Windows MR',
      prospectViewer: 'Oculus Rift, Meta Quest 2*, HTC Vive, or Windows MR',
    },
  },
  {
    category: 'Graphics card',
    copy: 'NVIDIA GTX 1070 equivalent or greater',
  },
  {
    category: 'CPU',
    copy: 'Intel i7-6700 equivalent or greater',
  },
  {
    category: 'Memory',
    copy: '16GB+ RAM',
  },
  {
    category: 'Video Output',
    copy: 'Compatible HDMI 1.3 video output',
  },
  {
    category: 'USB',
    copy: 'Ports 3x USB 3.0 ports plus 1x USB 2.0 port',
  },
]
