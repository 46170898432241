import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import {
  contains as cacheContains,
  set as cacheDismissed,
} from './localStorage'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import './Banner.global.scss'

class Banner extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    bannerID: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    ctaLink: PropTypes.string,
  }

  state = { closed: false }

  componentWillMount() {
    const { bannerID } = this.props
    if (cacheContains(bannerID)) this.setState({ closed: true })
  }

  dismissNotification = () => {
    const { bannerID } = this.props
    this.setState({ closed: true })
    cacheDismissed(bannerID)
  }

  callToAction = () => {
    const { ctaLink } = this.props
    this.dismissNotification()
    // navigate(ctaLink) Usually we would want to navigate to this prop. For The Wild post, open a new page.
    window.open(
      'https://blog.irisvr.com/the-wild-acquires-prospect-scope',
      '_blank'
    ) // remove this line when you want to switch back to the old CTA action
  }

  render() {
    const { closed } = this.state
    const { disabled, children, bannerID, view, ctaText } = this.props

    if (closed || disabled) return null

    return (
      <div className={`banner-container ${view}`}>
        <div className="content-container">
          <div className="main-container">{children}</div>
          {ctaText != '' && (
            <div className="cta-container">
              <button
                className="iris-cta-button-inverse"
                onClick={this.callToAction}
              >
                {ctaText}
              </button>
            </div>
          )}
        </div>
        <div className="dismiss-container">
          <SvgIcon className="dismiss" onClick={this.dismissNotification}>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </SvgIcon>
        </div>
      </div>
    )
  }
}

export default Banner
