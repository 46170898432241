import React from 'react'
import PropTypes from 'prop-types'
import FeaturedOn from './FeaturedOn'
import TrustedBy from './TrustedBy'

const Testimonials = ({
  widthClass,
  trustedTestimonialImages,
  featuredTestimonialImages,
}) => {
  return (
    <section className={`testimonials ${widthClass}`}>
      <FeaturedOn files={featuredTestimonialImages} />
      <TrustedBy files={trustedTestimonialImages} />
    </section>
  )
}

Testimonials.propTypes = {
  widthClass: PropTypes.string.isRequired,
  trustedTestimonialImages: PropTypes.shape().isRequired,
  featuredTestimonialImages: PropTypes.shape().isRequired,
}

export default Testimonials
