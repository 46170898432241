import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import LandingPage, { helmetProps } from '../components/LandingPage'
import { arrayToObject } from '../utils/image'

export const query = graphql`
  {
    trustedCorgan: file(
      name: { eq: "corgan" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 136) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    trustedBv: file(
      name: { eq: "bv" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 136) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    trustedEnnead: file(
      name: { eq: "ennead" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 111) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    trustedHmc: file(
      name: { eq: "hmc" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 22, maxWidth: 206) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    trustedMiele: file(
      name: { eq: "miele" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 68) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    trustedMortenson: file(
      name: { eq: "mortenson" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 87) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    trustedShop: file(
      name: { eq: "shop" }
      relativeDirectory: { eq: "logos/trusted/main" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 68) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    whyVRHero: file(
      name: { eq: "why-vr-hero" }
      relativeDirectory: { eq: "landing" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 430, height: 443, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    mobileWhyVRHero: file(
      name: { eq: "mobile-why-vr-hero" }
      relativeDirectory: { eq: "landing" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 323, height: 332, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    prospectLogo: file(
      name: { eq: "prospect" }
      relativeDirectory: { eq: "logos" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 215) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    scopeLogo: file(name: { eq: "scope" }, relativeDirectory: { eq: "logos" }) {
      id
      name
      childImageSharp {
        fixed(width: 158) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    icon3DModel: file(
      name: { eq: "3DModel" }
      relativeDirectory: { eq: "landing/products" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconDesktopSetup: file(
      name: { eq: "DesktopSetup" }
      relativeDirectory: { eq: "landing/products" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconHMD: file(
      name: { eq: "HMD" }
      relativeDirectory: { eq: "landing/products" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    archRecord: file(
      name: { eq: "arch-record" }
      relativeDirectory: { eq: "logos/featured" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 158) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    architizer: file(
      name: { eq: "architizer" }
      relativeDirectory: { eq: "logos/featured" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    bloomberg: file(
      name: { eq: "bloomberg" }
      relativeDirectory: { eq: "logos/featured" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 133) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    constructionDive: file(
      name: { eq: "construction-dive" }
      relativeDirectory: { eq: "logos/featured" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 190) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    techCrunch: file(
      name: { eq: "tech-crunch" }
      relativeDirectory: { eq: "logos/featured" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 136) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    wired: file(
      name: { eq: "wired" }
      relativeDirectory: { eq: "logos/featured" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 115) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    agora: file(
      name: { eq: "agora" }
      relativeDirectory: { eq: "landing/testimonials" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 168) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    hdr: file(
      name: { eq: "hdr" }
      relativeDirectory: { eq: "landing/testimonials" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 63) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    marmonmok: file(
      name: { eq: "marmonmok" }
      relativeDirectory: { eq: "landing/testimonials" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 147) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    miele: file(
      name: { eq: "miele" }
      relativeDirectory: { eq: "landing/testimonials" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 97) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    mortenson: file(
      name: { eq: "mortenson" }
      relativeDirectory: { eq: "landing/testimonials" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 116) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps1: file(
      name: { eq: "desktop-collaboration" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 575, height: 500, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps2: file(
      name: { eq: "desktop-file-support" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 575, height: 500, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps3: file(
      name: { eq: "desktop-comfort" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 525, height: 450, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps4: file(
      name: { eq: "desktop-immersive" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 450, height: 386, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps1Mobile: file(
      name: { eq: "mobile-collaboration" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 320, height: 290, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps2Mobile: file(
      name: { eq: "mobile-file-support" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 320, height: 224, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps3Mobile: file(
      name: { eq: "mobile-comfort" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 320, height: 229, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    valueProps4Mobile: file(
      name: { eq: "mobile-immersive" }
      relativeDirectory: { eq: "landing/value-props" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 280, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => {
  return (
    <>
      <Helmet {...helmetProps} />
      <LandingPage
        whyVRHero={data.whyVRHero}
        mobileWhyVRHero={data.mobileWhyVRHero}
        trustedImages={arrayToObject([
          data.trustedCorgan,
          data.trustedMortenson,
          data.trustedShop,
          data.trustedBv,
        ])}
        trustedTestimonialImages={arrayToObject([
          data.mortenson,
          // data.miele,
          // data.marmonmok,
          // data.agora,
          // data.hdr,
        ])}
        productImages={arrayToObject([
          data.icon3DModel,
          data.iconDesktopSetup,
          data.iconHMD,
          data.prospectLogo,
        ])}
        featuredTestimonialImages={arrayToObject([
          data.archRecord,
          data.architizer,
          data.bloomberg,
          data.constructionDive,
          data.techCrunch,
          data.wired,
        ])}
        valuePropsImages={arrayToObject([
          data.valueProps4,
          data.valueProps1Mobile,
          data.valueProps2Mobile,
          data.valueProps3Mobile,
          data.valueProps4Mobile,
          data.valueProps1,
          data.valueProps2,
          data.valueProps3,
        ])}
      />
    </>
  )
})
