// Returns the sorted values of a map of strings to numbers
export function sortMapValues(o) {
  return Object.values(o).sort((aa, bb) => {
    let a = typeof aa === 'string' ? parseInt(aa, 10) : aa
    let b = typeof bb === 'string' ? parseInt(bb, 10) : bb
    return a === b ? 0 : a < b ? -1 : 1
  })
}

export default function computeMediaType({ width, breakpoints }) {
  if (!breakpoints) return ''
  // Reverses keys and values (i.e., {1:2} becomes {2:1}
  // Maps breakpoint values to media-type strings
  let mediaTypes = Object.entries(breakpoints).reduce((obj, item) => {
    let key = item[0]
    let value = item[1]
    obj[value] = `${key}`
    return obj
  }, {})
  // array of breakpoint int values, sorted lowest to greatest
  let sortedBreakpointValues = sortMapValues(breakpoints)
  for (let i = 0; i < sortedBreakpointValues.length; i++) {
    let breakpoint = sortedBreakpointValues[i]
    let mediaType = mediaTypes[breakpoint]
    if (width < breakpoint) {
      return mediaType
    }
  }
  // Return no CSS class (empty string) if nothing matches
  return ''
}
