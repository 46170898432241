import React from 'react'
import Slideshow from './Slideshow'

const TrustedBy = ({ files }) => (
  <div className="trusted-by-container">
    <div className="trusted-by">
      <p className="header">Trusted By</p>
      <Slideshow files={files} />
    </div>
  </div>
)

export default TrustedBy
