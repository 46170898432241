import React from 'react'
import PropTypes from 'prop-types'
import Value from './Value'
import { metadata } from './metadata'
import { IrisImg } from '../../../Shared'

const Filenames = {
  desktop: {
    collaboration: 'desktop-collaboration',
    fileSupport: 'desktop-file-support',
    comfort: 'desktop-comfort',
    immersive: 'desktop-immersive',
  },
  mobile: {
    collaboration: 'mobile-collaboration',
    fileSupport: 'mobile-file-support',
    comfort: 'mobile-comfort',
    immersive: 'mobile-immersive',
  },
}

const DesktopRowWrapper = ({ children, isLast, isFirst }) => {
  return (
    <div
      style={{
        paddingBottom: isLast ? 0 : 50,
        marginTop: isFirst || isLast ? -100 : 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 950,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

const ValueProps = ({ valuePropsImages, shouldStack }) => {
  return (
    <section
      className={`value-props ${shouldStack ? 'ivr-stacked' : 'ivr-row'}`}
    >
      {!shouldStack ? (
        <div className="value-props-container">
          <DesktopRowWrapper isFirst>
            <Value {...metadata[0]} />
            <IrisImg
              className="collaboration"
              alt="Collaboration"
              file={valuePropsImages[Filenames.desktop.collaboration]}
            />
          </DesktopRowWrapper>
          <DesktopRowWrapper>
            <IrisImg
              className="immersive"
              alt="Immersive"
              file={valuePropsImages[Filenames.desktop.immersive]}
            />
            <Value {...metadata[1]} />
          </DesktopRowWrapper>
          <DesktopRowWrapper>
            <Value {...metadata[2]} />
            <IrisImg
              className="file-support"
              alt="File Support"
              file={valuePropsImages[Filenames.desktop.fileSupport]}
            />
          </DesktopRowWrapper>
          <DesktopRowWrapper isLast>
            <IrisImg
              className="comfort"
              alt="Comfort"
              file={valuePropsImages[Filenames.desktop.comfort]}
            />
            <Value {...metadata[3]} />
          </DesktopRowWrapper>
        </div>
      ) : (
        <div className="value-props-container">
          <IrisImg
            className="collaboration"
            style={{ marginTop: -50, marginBottom: 40 }}
            alt="Collaboration"
            file={valuePropsImages[Filenames.mobile.collaboration]}
          />
          <div className="value-prop-row">
            <Value {...metadata[0]} />
          </div>
          <div className="image-container collaboration-container">
            <IrisImg
              className="collaboration"
              style={{ marginRight: 7 }}
              alt="Collaboration"
              file={valuePropsImages[Filenames.mobile.immersive]}
            />
          </div>
          <div className="value-prop-row immersive-container">
            <Value {...metadata[1]} cta />
          </div>
          <div className="image-container">
            <IrisImg
              className="file-support"
              alt="File Support"
              file={valuePropsImages[Filenames.mobile.fileSupport]}
            />
          </div>
          <Value {...metadata[2]} />
          <div
            className="image-container comfort-container"
            style={{ marginTop: 40, marginBottom: 15 }}
          >
            <IrisImg
              className="comfort"
              alt="Comfort"
              file={valuePropsImages[Filenames.mobile.comfort]}
            />
          </div>
          <Value {...metadata[3]} />
        </div>
      )}
    </section>
  )
}

ValueProps.propTypes = {
  shouldStack: PropTypes.bool.isRequired,
}

export default ValueProps
