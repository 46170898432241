import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'VR for Architecture, Engineering, and Construction'
const description =
  'Immersive walkthroughs and VR meetings in one click. Works with SketchUp, Revit, Navisworks, Rhino files in Oculus Rift, Meta Quest 2, HTC Vive, Windows MR, and more.'
const url = getWindowLocationOrigin()
const image = 'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Iris.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
