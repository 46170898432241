export const metadata = [
  {
    logoFilename: 'mortenson',
    quote:
      'IrisVR is a tool that gives us the ability to do something in the AEC world that we have never been able to do before; explore, refine and explore again with full spatial understanding in an extremely compressed timeframe.',
    author: {
      name: 'Taylor Cupp',
      title: 'Project Solutions Technologist',
    },
    company: 'Mortenson',
    websiteUrl: 'https://www.mortenson.com/',
  },
  // {
  //   logoFilename: 'miele',
  //   quote:
  //     "VR is providing significant cost savings and workflow efficiencies, but is also providing a tangible productivity benefit in that it's speeding up the approval process.",
  //   author: { name: 'Kym Porter' },
  //   company: 'Miele Australia',
  //   websiteUrl: 'https://www.miele.com/en/com/index.htm',
  // },
  // {
  //   logoFilename: 'marmonmok',
  //   quote:
  //     'Our client now knows that they can trust us to efficiently execute a design without wasting their time or money, and can provide a 3D service that allows their members to truly feel like their budget is well managed.',
  //   author: { name: 'Carlos Lucio ASAI/ACM' },
  //   company: 'Marmon Mok Architecture',
  //   websiteUrl: 'https://marmonmok.com/',
  // },
  // {
  //   logoFilename: 'agora',
  //   quote:
  //     'Offering VR provides our clients the ability to impress their boards, contributors and major stockholders. Convincing these people to invest in and support the project can be the piece that makes or breaks a dream.',
  //   author: {
  //     name: 'Cheri A. Hulse, AIA',
  //     title: 'Senior Architect and Partner',
  //   },
  //   company: 'Agora Architecture',
  //   websiteUrl: 'http://www.gordonarch.com/',
  // },
  // {
  //   logoFilename: 'hdr',
  //   quote:
  //     'Great products, great company, great service, easy to use, effective.',
  //   author: {
  //     name: 'Daniel Williamson, AIA, NCARB',
  //     title: 'Digital Design Leader',
  //   },
  //   company: 'HDR',
  //   websiteUrl: 'https://www.hdrinc.com',
  // },
]
