import React from 'react'
import bowser from 'bowser'
import PropTypes from 'prop-types'
import splashVideo from '../../../../images/MR_Splash_Video.mp4'
import { IrisCTALinkButton, Mp4Video } from '../../../Shared'

import EmailRegistrationForm from '../../../Shared/EmailRegistrationForm'

import RevitLogo from '../../../../../public/images/integrations/Revit/1_RevitLogo.png'
import RhinoLogo from '../../../../../public/images/integrations/Rhino/1_RhinoLogo.png'
import NavisLogo from '../../../../../public/images/integrations/Navisworks/NavisworksLogo.png'
import SketchUpLogo from '../../../../../public/images/integrations/SketchUp/1_SketchUpLogo.png'

const SplashVideo = ({ browserWidth, shouldDynamicallyResize }) => {
  // HACK: Address the odd sizing of the video window in mobile safari
  const height = browserWidth > 540 ? 'auto' : 6 + browserWidth * (277 / 504)

  return (
    <div
      style={{
        maxWidth: 900,
        width: '100%',
        margin: 'auto',
      }}
    >
      <Mp4Video
        video={splashVideo}
        style={{ width: '100%', top: 0, height: height }}
      />
    </div>
  )
}

const SplashContainer = ({ browserWidth }) => {
  let mediaType
  if (browserWidth > 1200) mediaType = 'desktop'
  else if (browserWidth > 900) mediaType = 'laptop'
  else if (browserWidth > 550) mediaType = 'tablet'
  else mediaType = 'mobile'

  let containerType
  if (browserWidth > 900) containerType = 'splash-container-row'
  else containerType = 'splash-container-stacked'

  return (
    <div>
      <div className="splash-container">
        <div className="splash-container-content">
          <div className="top-content">
            <div className="text-content-container">
              <div className="title-container">
                <p className="title">
                  <div class="accent-border purple"></div>
                  <span class="fb">VR </span>
                  <span class="fb">Platform for the </span>
                  <span class="fb">Building Industry</span>
                </p>
              </div>
              <EmailRegistrationForm />
            </div>
            <IntegrationSpotlight browserWidth={browserWidth} />
          </div>
          <div className="bottom-content">
            <SplashVideo
              browserWidth={browserWidth}
              shouldDynamicallyResize={['mobile', 'tablet'].includes(mediaType)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const IntegrationSpotlight = ({ browserWidth }) => {
  const mobileBreakpoint = 540

  const iconHeight = browserWidth > mobileBreakpoint ? 48 : 43
  const maxWidth = browserWidth > mobileBreakpoint ? 430 : 320
  return (
    <div
      style={{
        maxWidth: 600,
        margin: 'auto',
        textAlign: 'center',
        marginTop: browserWidth > mobileBreakpoint ? 85 : 55,
        marginBottom: 85,
      }}
    >
      <div
        style={{
          width: '80%',
          margin: 'auto auto 34px auto',
          fontSize: '1.2em',
        }}
      >
        <p>
          Data-rich, native file {browserWidth > 540 ? '' : <br />}integrations
          include:
        </p>
      </div>
      <div
        style={{
          width: '90%',
          maxWidth: maxWidth,
          margin: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '25%' }}>
          <a href="integrations/navisworks">
            <img style={{ height: iconHeight }} src={NavisLogo} />
          </a>
        </div>
        <div style={{ width: '25%' }}>
          <a href="integrations/revit">
            <img style={{ height: iconHeight }} src={RevitLogo} />
          </a>
        </div>
        <div style={{ width: '25%' }}>
          <a href="integrations/rhino">
            <img style={{ height: iconHeight }} src={RhinoLogo} />
          </a>
        </div>
        <div style={{ width: '25%' }}>
          <a href="integrations/sketchup">
            <img style={{ height: iconHeight }} src={SketchUpLogo} />
          </a>
        </div>
      </div>
    </div>
  )
}

SplashContainer.propTypes = {
  browserWidth: PropTypes.number.isRequired,
}

export default SplashContainer
