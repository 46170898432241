export const metadata = {
  wired: {
    alt:
      'Logo for Wired, a magazine that focuses on how emerging technologies affect culture',
    url:
      'https://www.wired.com/2016/11/vr-totally-changing-architects-dream-buildings/',
    order: 1,
  },
  architizer: {
    alt:
      'Logo for Architizer, a portfolio database, media site, and architectural manufacturer search engine that’s transforming the architecture community',
    url: 'https://architizer.com/blog/practice/tools/iris-vr/',
    order: 2,
  },
  'tech-crunch': {
    alt: 'Logo for TechCrunch, a news website dedicated to the tech scene',
    url: 'https://techcrunch.com/2016/10/25/irisvr-series-a/',
    order: 3,
  },
  'arch-record': {
    alt:
      'Logo for Architectural Record, the #1 source for design news, architect continuing education, and info on sustainability, houses, projects, and architectural products',
    url:
      'https://www.architecturalrecord.com/articles/11962-virtual-reality-and-architecture?v=preview',
    order: 4,
  },
  'construction-dive': {
    alt:
      'Logo for Construction Dive, news and analysis for construction industry executives.',
    url:
      'https://www.constructiondive.com/news/reducing-design-coordination-errors-with-vr-ar/525645/',
    order: 5,
  },
  bloomberg: {
    alt: 'Logo for Bloomberg News',
    url:
      'https://www.bloomberg.com/news/videos/2017-05-19/adding-virtual-reality-to-architecture-video',
    order: 6,
  },
}
