import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { MenuItemLink } from './BurgerMenu/MenuItemLink'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Burger from './BurgerMenu'
import { IrisImg, IrisCTALinkButton } from '../../../Shared'
import Dropdown from './Dropdown'
import './Navbar.scss'
import { getDomain, getSignupUrl } from '../../../../utils/url'
import ProductDropdownContents from './ProductDropdownContents'

const signupFormURL = getSignupUrl()

const navbarIsTransparentOnPath = path => {
  const transNavPages = ['/']
  return window.innerWidth > 1100 && transNavPages.includes(path)
}

const staticMenuItemData = {
  customers: {
    to: '/customers',
    title: 'Customers',
  },
  pricing: {
    to: '/pricing',
    title: 'Pricing',
  },
  blog: {
    href: 'https://blog.irisvr.com/',
    title: 'Blog',
  },
  signin: {
    title: 'Sign In',
    href: `https://account.${getDomain()}/prospect`,
  },
}

const StaticMenuItems = () => {
  return Object.keys(staticMenuItemData).map((menuItemKey, idx) => {
    const menuItemDetails = staticMenuItemData[menuItemKey]
    return (
      <div key={`${menuItemKey}-${idx}`}>
        <MenuItemLink
          {...menuItemDetails}
          customStyles={{
            textTransform: 'uppercase',
            fontSize: '14px',
            letterSpacing: 1,
          }}
        />
      </div>
    )
  })
}

const SignInButton = () => (
  <IrisCTALinkButton
    href={`https://account.${getDomain()}/prospect`}
    cta={'Sign in'}
    theme={'inverse'}
    customStyles={{ paddingLeft: 26, paddingRight: 26 }}
  />
)

const FreeTrialButton = () => (
  <IrisCTALinkButton
    href={signupFormURL}
    cta="Try for free"
    customStyles={{ paddingLeft: 26, paddingRight: 26 }}
  />
)

function navbarWithGraphQLData(data) {
  return class extends React.PureComponent {
    constructor(props) {
      super(props)

      this.state = {
        burgerMenuOpen: false,
        data,
        width: window.innerWidth,
        scrollY: 0,
        scrollTop: 0,
      }

      this.handleScroll = this.handleScroll.bind(this)
      this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
      document.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('resize', this.handleResize)
    }

    handleScroll(evt) {
      const scrollPos =
        document.body.scrollTop || document.documentElement.scrollTop
      this.setState({ scrollTop: scrollPos })
    }

    handleResize(evt) {
      this.setState({ width: window.innerWidth, scrollY: this.state.scrollY })
    }

    handleToggle = () => {
      this.setState(state => ({ burgerMenuOpen: !state.burgerMenuOpen }))
    }

    handleClose = event => {
      if (this.anchorEl.contains(event.target)) {
        return
      }
      this.setState({ burgerMenuOpen: false })
    }

    render() {
      const { width, burgerMenuOpen, data } = this.state

      // HACK: This was addded since the value coming from the scroll event were inconsistent, so the state change triggers a re-render, then we get reliable values from the document
      const scrollY =
        document.body.scrollTop || document.documentElement.scrollTop

      const IrisLogo = (
        <Link to={'/'}>
          <IrisImg
            file={data.irisLogo}
            style={{ display: 'flex' }}
            alt={'Prospect logo'}
          />
        </Link>
      )

      const BurgerMenu = (
        <div className="burger">
          <IconButton
            buttonRef={node => (this.anchorEl = node)}
            style={{
              marginLeft: '-1.2rem',
              marginRight: '1rem',
              background: 'transparent',
            }}
            color="inherit"
            onClick={this.handleToggle}
            aria-owns={burgerMenuOpen ? 'menu-list-grow' : null}
            aria-haspopup="true"
            aria-label="Menu"
            disableRipple
          >
            <MenuIcon />
          </IconButton>
          <Burger
            data={data}
            anchorEl={this.anchorEl}
            handleClose={this.handleClose}
            handleToggle={this.handleToggle}
            open={burgerMenuOpen}
          />
        </div>
      )
      // Size of $burger-menu-break in Navbar.scss

      const isDesktop = width > 1180
      const isTop = scrollY < 94

      const shouldBeTransparent =
        isDesktop &&
        isTop &&
        navbarIsTransparentOnPath(window.location.pathname)

      return (
        <div
          className={
            shouldBeTransparent ? 'navbar transparent-nav' : 'navbar light-nav'
          }
        >
          <AppBar
            className="navbar-appbar"
            position="static"
            color="primary"
            square
          >
            <Toolbar
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {BurgerMenu}
                {IrisLogo}
              </div>

              <div className="menu-link">
                <Dropdown dropdownLabel="Product">
                  <ProductDropdownContents />
                </Dropdown>
                <StaticMenuItems />
                {/* <div style={{ marginRight: 15 }}>
                  <SignInButton />
                </div> */}
                <FreeTrialButton />
              </div>
            </Toolbar>
          </AppBar>
        </div>
      )
    }
  }
}

export { navbarIsTransparentOnPath }

export default () => (
  <StaticQuery
    query={graphql`
      query NavbarIconQuery {
        irisLogo: file(relativePath: { eq: "logos/prospect.png" }) {
          childImageSharp {
            fixed(width: 160) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        prospectLogo: file(
          relativePath: { eq: "icons/navbar/products/prospect.png" }
        ) {
          childImageSharp {
            fixed(width: 25) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        headset: file(
          relativePath: { eq: "icons/navbar/products/headset_icon.png" }
        ) {
          childImageSharp {
            fixed(width: 32) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const HOC = navbarWithGraphQLData(data)
      return <HOC />
    }}
  />
)
