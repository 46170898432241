import React from 'react'

import './styles.scss'
import { getSignupUrl } from '../../../utils/url'
const signupFormURL = getSignupUrl()

const sendEvent = () => {
  // Track event
  if (window.analytics) {
    window.analytics.track('signup_email_submitted')
  }
}

const EmailRegistrationForm = () => (
  <div className="email-registration-form button-container">
    <form
      method="get"
      action={signupFormURL}
      enctype="application/x-www-form-urlencoded"
    >
      <input
        name="email"
        class="email-input"
        placeholder="Your work email"
        type="text"
      />
      <span className="cta-button">
        <button type="submit" onClick={sendEvent}>
          Try for free
        </button>
      </span>
    </form>
  </div>
)

export default EmailRegistrationForm
