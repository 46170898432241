import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'
import './IrisCollapsibleList.global.scss'

class IrisCollapsibleList extends Component {
  static defaultProps = {
    open: false,
  }

  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
      .isRequired,
    open: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
  }

  state = {
    open: this.props.open,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open })
  }

  onTriggerClick = () => this.setState({ open: !this.state.open })

  render() {
    const { title, open, list } = this.props

    const closeButton = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M7 14l5-5 5 5z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    )
    const openButton = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M7 10l5 5 5-5z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    )

    const Trigger = (
      <div className="trigger-container" onClick={this.onTriggerClick}>
        <p>{title}</p>
        <p className="trigger-icon">
          {this.state.open ? closeButton : openButton}
        </p>
      </div>
    )

    return (
      <div className="iris-collapsible">
        <Collapsible
          trigger={Trigger}
          open={open}
          easing="ease-in-out"
          transitionTime={300}
        >
          <ul>
            {list.map((l, i) => (
              <li key={i}>{l}</li>
            ))}
          </ul>
        </Collapsible>
      </div>
    )
  }
}

export default IrisCollapsibleList
