import React from 'react'
import Slider from 'react-slick'
import { metadata } from './metadata'
import { IrisImg } from '../../../../../Shared'
import './Slideshow.scss'

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <span className="slick-arrow slick-prev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </svg>
    </span>
  ),
  nextArrow: (
    <span className="slick-arrow slick-next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </svg>
    </span>
  ),
}

const Slideshow = ({ files }) => {
  if (metadata.length > 1) {
    return (
      <Slider {...settings} className="trusted-by-slideshow">
        {// react-slick library does not allow the
        // slide to be its own component, as it needs
        // to apply custom classes, HTML targets.
        metadata.map(
          (
            {
              logoFilename,
              websiteUrl,
              company,
              quote,
              author: { name: authorName, title: authorTitle },
            },
            i
          ) => (
            <div key={`${logoFilename}-${i}`} className="ivr-slide">
              <div className="logo-container">
                <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                  <IrisImg
                    alt={company}
                    className={`logo ${logoFilename}-logo`}
                    file={files[logoFilename]}
                  />
                </a>
              </div>
              <div className="text-container">
                <div className="quote-container">
                  <p className="quote">{`"${quote}"`}</p>
                </div>
                <div className="author-container">
                  <p className="author">{authorName}</p>
                  {authorTitle && <p className="author-title">{authorTitle}</p>}
                </div>
              </div>
            </div>
          )
        )}
      </Slider>
    )
  }

  const {
    logoFilename,
    websiteUrl,
    company,
    quote,
    author: { name: authorName, title: authorTitle },
  } = metadata[0]

  return (
    <div className="slick-slider trusted-by-slideshow">
      <div key={`${logoFilename}`} className="ivr-slide">
        <div className="logo-container">
          <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
            <IrisImg
              alt={company}
              className={`logo ${logoFilename}-logo`}
              file={files[logoFilename]}
            />
          </a>
        </div>
        <div className="text-container">
          <div className="quote-container">
            <p className="quote">{`"${quote}"`}</p>
          </div>
          <div className="author-container">
            <p className="author">{authorName}</p>
            {authorTitle && <p className="author-title">{authorTitle}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slideshow
