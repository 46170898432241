export const LogoFilenames = {
  main: {
    ennead: 'ennead',
    miele: 'miele',
    corgan: 'corgan',
    mortenson: 'mortenson',
    shop: 'shop',
    hmc: 'hmc',
    bv: 'bv',
  },
  architecture: {
    marmonMok: 'marmon-mok',
    ennead: 'ennead',
    callison: 'callison',
    shop: 'shop',
    hmc: 'hmc',
  },
  engineering: {
    crb: 'crb',
    omicron: 'omicron',
    bilfinger: 'bilfinger',
    solagro: 'solagro',
    smw: 'smw',
  },
  construction: {
    gse: 'gse',
    paric: 'paric',
    mortenson: 'mortenson',
    hagerman: 'hagerman',
    gly: 'gly',
  },
  educators: {
    ltu: 'lawrence-tech',
    uva: 'uva',
    stThomas: 'st-thomas',
    isu: 'isu',
    kansasState: 'kansas-state',
  },
  students: {
    ltu: 'lawrence-tech',
    uva: 'uva',
    stThomas: 'st-thomas',
    isu: 'isu',
    kansasState: 'kansas-state',
  },
}

export default {
  main: {
    // [LogoFilenames.main.ennead]: {
    //   linkUrl: 'http://www.ennead.com/',
    //   imgAlt: 'ennead',
    //   order: 1,
    //   width: '11.1rem',
    // },
    // [LogoFilenames.main.miele]: {
    //   linkUrl: 'https://www.miele.com/en/com/index.htm',
    //   imgAlt: 'miele',
    //   order: 2,
    //   width: '6.8rem',
    // },
    [LogoFilenames.main.corgan]: {
      linkUrl: 'https://www.corgan.com/',
      imgAlt: 'corgan',
      order: 1,
      width: '13.6rem',
    },
    [LogoFilenames.main.mortenson]: {
      linkUrl: 'https://www.mortenson.com/',
      imgAlt: 'mortenson',
      order: 2,
      width: '8.7rem',
    },
    [LogoFilenames.main.shop]: {
      linkUrl: 'https://www.shoparc.com/',
      imgAlt: 'shop',
      order: 3,
      width: '6.8rem',
    },
    [LogoFilenames.main.bv]: {
      linkUrl: 'https://www.bv.com/',
      imgAlt: 'bv',
      order: 4,
      width: '6.8rem',
    },
    // [LogoFilenames.main.hmc]: {
    //   linkUrl: 'https://hmcarchitects.com/',
    //   imgAlt: 'hmc',
    //   order: 6,
    //   width: '20.6rem',
    // },
  },
  architecture: {
    [LogoFilenames.architecture.marmonMok]: {
      linkUrl: 'https://marmonmok.com/',
      imgAlt: 'marmon',
      order: 1,
      width: '14.5rem',
    },
    [LogoFilenames.architecture.ennead]: {
      linkUrl: 'http://www.ennead.com/',
      imgAlt: 'ennead',
      order: 2,
      width: '11.1rem',
    },
    [LogoFilenames.architecture.callison]: {
      linkUrl: 'https://www.callisonrtkl.com/',
      imgAlt: 'callison',
      order: 3,
      width: '17.6rem',
    },
    [LogoFilenames.architecture.shop]: {
      linkUrl: 'http://www.shoparc.com/',
      imgAlt: 'shop',
      order: 4,
      width: '5.9rem',
    },
    [LogoFilenames.architecture.hmc]: {
      linkUrl: 'https://hmcarchitects.com/',
      imgAlt: 'hmc',
      order: 5,
      width: '20rem',
    },
  },
  engineering: {
    [LogoFilenames.engineering.crb]: {
      linkUrl: 'https://www.crbusa.com/',
      imgAlt: 'crb',
      order: 1,
    },
    [LogoFilenames.engineering.omicron]: {
      linkUrl: 'https://omicronaec.com/',
      imgAlt: 'omicron',
      order: 2,
    },
    [LogoFilenames.engineering.bilfinger]: {
      linkUrl: 'http://bilfinger.com/',
      imgAlt: 'bilfinger',
      order: 3,
    },
    [LogoFilenames.engineering.solagro]: {
      linkUrl: 'http://www.solagro.es/',
      imgAlt: 'solagro',
      order: 4,
    },
    [LogoFilenames.engineering.smw]: {
      linkUrl: 'http://smwllc.com/',
      imgAlt: 'smw',
      order: 5,
    },
  },
  construction: {
    [LogoFilenames.construction.gse]: {
      linkUrl: 'http://www.gsegroup.com/',
      imgAlt: 'gse',
      order: 1,
    },
    [LogoFilenames.construction.paric]: {
      linkUrl: 'https://paric.com/',
      imgAlt: 'paric',
      order: 2,
    },
    [LogoFilenames.construction.mortenson]: {
      linkUrl: 'https://www.mortenson.com/',
      imgAlt: 'mortenson',
      order: 3,
    },
    [LogoFilenames.construction.hagerman]: {
      linkUrl: 'https://www.thehagermangroup.com/',
      imgAlt: 'hagerman',
      order: 4,
    },
    [LogoFilenames.construction.gly]: {
      linkUrl: 'https://gly.com/',
      imgAlt: 'gly',
      order: 5,
    },
  },
  educators: {
    [LogoFilenames.educators.ltu]: {
      linkUrl: 'https://www.ltu.edu/',
      imgAlt: 'lawrence-tech',
      order: 1,
    },
    [LogoFilenames.educators.uva]: {
      linkUrl: 'http://virginia.edu/',
      imgAlt: 'uva',
      order: 2,
    },
    [LogoFilenames.educators.stThomas]: {
      linkUrl: 'http://www.stthomas.edu/',
      imgAlt: 'u-stthomas',
      order: 3,
    },
    [LogoFilenames.educators.isu]: {
      linkUrl: 'https://www.iastate.edu/',
      imgAlt: 'isu',
      order: 4,
    },
    [LogoFilenames.educators.kansasState]: {
      linkUrl: 'http://apdesign.k-state.edu/',
      imgAlt: 'ap-design',
    },
  },
  students: {
    [LogoFilenames.students.ltu]: {
      linkUrl: 'https://www.ltu.edu/',
      imgAlt: 'lawrence-tech',
      order: 1,
    },
    [LogoFilenames.students.uva]: {
      linkUrl: 'http://virginia.edu/',
      imgAlt: 'uva',
      order: 2,
    },
    [LogoFilenames.students.stThomas]: {
      linkUrl: 'http://www.stthomas.edu/',
      imgAlt: 'u-stthomas',
      order: 3,
    },
    [LogoFilenames.students.isu]: {
      linkUrl: 'https://www.iastate.edu/',
      imgAlt: 'isu',
      order: 4,
    },
    [LogoFilenames.students.kansasState]: {
      linkUrl: 'http://apdesign.k-state.edu/',
      imgAlt: 'ap-design',
    },
  },
}
