import React from 'react'
import { Link } from 'gatsby'
import { IrisImg, IrisCTALinkButton } from '../../../Shared'
import { ViewTypes } from '../../breakpoints'
import metadata from './metadata'
import { getSignupUrl } from '../../../../utils/url'

const ProspectTrial = ({ productImages, viewType }) => {
  const activeViewType =
    Object.keys(viewType)
      .find(k => viewType[k])
      .slice(2) || ViewTypes.rowWide

  return (
    <div className="prospect-trial-container">
      <div className={`prospect-trial-card ${activeViewType}`}>
        <div className="prospect-header" />
        <div className="prospect-boxes">
          <div className="box specs">
            <div className="header-container">
              <p className="header">Here's all you need to get started!</p>
            </div>
            <div className="spec-list">
              {metadata.map(spec => (
                <div key={spec.name} className="spec">
                  <div className="img-container">
                    <IrisImg
                      className={spec.name}
                      alt={spec.name}
                      file={productImages[spec.name]}
                    />
                  </div>
                  <div className="text-content">
                    <p className="title">{spec.title}</p>
                    <p className="description">
                      {activeViewType === 'StackedNarrow'
                        ? spec.description.mobile
                        : spec.description.standard}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="box cta">
            <div className="header-container">
              <IrisImg
                className="prospect-logo"
                alt="prospect"
                file={productImages.prospect}
              />
            </div>
            <div className="body-container">
              <p className="title">Try Prospect Free for 14 days</p>
            </div>
            <IrisCTALinkButton href={getSignupUrl()} cta="Start 14-Day Trial" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProspectTrial
