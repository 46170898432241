import React from 'react'
import { Link } from 'gatsby'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import './MenuItemLink.scss'

export const MenuItemLink = ({ to, href, title, customStyles = {} }) => {
  const defaultStyles = {
    textDecoration: 'none',
    color: 'black',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 16,
    fontFamily: 'irisvr',
    width: '100%',
    paddingTop: 12,
    paddingBottom: 12,
  }
  const style = {
    ...defaultStyles,
    ...customStyles,
  }

  if (href) {
    return (
      <MenuItem className="MenuItem" disableRipple disableTouchRipple>
        <a href={href} style={style}>
          {title}
        </a>
      </MenuItem>
    )
  }

  return (
    <MenuItem className="MenuItem" disableRipple disableTouchRipple>
      <Link to={to} style={style}>
        {title}
      </Link>
    </MenuItem>
  )
}
