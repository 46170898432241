import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { createUseStyles, createTheming } from 'react-jss'

const IrisCTALinkButtonThemeContext = React.createContext({})
const theming = createTheming(IrisCTALinkButtonThemeContext)
const { ThemeProvider } = theming

const useStyles = createUseStyles(
  theme => ({
    button: {
      borderRadius: 0,
      textTransform: 'uppercase',
      fontSize: 14,
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 28,
      paddingRight: 28,
      lineHeight: '16px',
      letterSpacing: 1,
      transition: '0.3s ease',
      '&:focus': {
        outline: 'none',
      },
      ...theme,
    },
  }),
  { theming }
)

export const colors = {
  purple: '#534dc8',
  lightPurple: '#727fe0',
  white: 'white',
  black: 'black',
}

const themes = {
  primary: {
    color: colors.white,
    backgroundColor: colors.purple,
    fontFamily: 'irisvr',
    border: `2px solid ${colors.purple}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.lightPurple,
      borderColor: colors.lightPurple,
    },
  },
  primaryOnPurple: {
    color: colors.white,
    backgroundColor: colors.purple,
    fontFamily: 'irisvr',
    border: `2px solid ${colors.white}`,
    '&:hover': {
      color: colors.purple,
      backgroundColor: colors.white,
      borderColor: colors.white,
    },
  },
  blackOnBlack: {
    color: colors.white,
    backgroundColor: colors.black,
    fontFamily: 'irisvr',
    border: `2px solid ${colors.white}`,
    '&:hover': {
      color: colors.purple,
      backgroundColor: colors.white,
      borderColor: colors.white,
    },
  },
  inverse: {
    color: colors.purple,
    backgroundColor: colors.white,
    fontFamily: 'irisvr-bold',
    border: `2px solid ${colors.purple}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.lightPurple,
      borderColor: colors.lightPurple,
    },
  },
}

// Overrides
const calculatedThemeAndCustomProps = (customStyles = {}, theme) => {
  if (customStyles.backgroundHoverColor) {
    return {
      '&:hover': {
        ...theme['&:hover'],
        backgroundColor: customStyles.backgroundHoverColor,
        // We always want the border color to be the same as the background color on hover; doing this so we don't have to send in the same custom color twice.
        borderColor: customStyles.backgroundHoverColor,
      },
      ...customStyles,
    }
  }

  return customStyles
}

const StyledCTALinkButton = ({ to, href, target, rel, onClick, cta }) => {
  // We should be able to pass in props like useStyles(props) but unfortunately
  // this breaks hot reloading for some reason
  const classes = useStyles()

  if (onClick) {
    return (
      <button className={classes.button} onClick={onClick}>
        {cta}
      </button>
    )
  }

  if (href) {
    return (
      <a
        href={href}
        {...(target ? (target = { target }) : {})}
        {...(rel ? (rel = { rel }) : {})}
      >
        <button className={classes.button}>{cta}</button>
      </a>
    )
  }

  return (
    <Link to={to}>
      <button className={classes.button}>{cta}</button>
    </Link>
  )
}

export const IrisCTALinkButton = ({
  theme = 'primary',
  to,
  href,
  target,
  rel,
  onClick,
  cta,
  customStyles,
}) => {
  const chosenTheme = themes[theme]

  const calculatedTheme = {
    ...chosenTheme,
    ...calculatedThemeAndCustomProps(customStyles, chosenTheme),
  }

  return (
    <ThemeProvider theme={calculatedTheme}>
      <StyledCTALinkButton
        to={to}
        href={href}
        target={target}
        rel={rel}
        onClick={onClick}
        cta={cta}
      />
    </ThemeProvider>
  )
}

IrisCTALinkButton.propTypes = {
  cta: PropTypes.any.isRequired,
  theme: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,
  customStyles: PropTypes.objectOf(PropTypes.any),
}
