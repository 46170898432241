import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import withSizes from 'react-sizes'
import computeMediaType from '../breakpoints'
import Footer from './Footer'
import Navbar from './Navbar'
import Notifications from './Notifications'
import './Layout.scss'

import { navbarIsTransparentOnPath } from './Navbar'

class Layout extends React.Component {
  componentDidMount() {
    this.setState({ width: window.innerWidth })
    /**
     * If someone navigates to a page via a Gatsby Link,
     * we'll need to ping Drift to get their plugin to render:
     * https://devdocs.drift.com/docs/contact-properties#section-driftpage
     */
    window.drift && window.drift.page()
  }

  render() {
    const { breakpoints, children, reactSizesWidth } = this.props

    // This should only be true at build time.
    if (!reactSizesWidth && !this.state) {
      return null
    }

    const width = reactSizesWidth || this.state.width

    return (
      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                title
              }
              buildTime
            }
          }
        `}
        render={data => {
          const footerMediaType = computeMediaType({
            width,
            breakpoints: {
              tiny: 320,
              extraSmall: 480,
              small: 768,
              medium: 992,
              large: 1200,
            },
          })
          const widthClass = breakpoints
            ? computeMediaType({ width, breakpoints })
            : ''
          const newChildrenProps = {
            widthClass,
            width,
          }

          const navIsTransparent = navbarIsTransparentOnPath(
            window.location.pathname
          )
          const barTheme = navIsTransparent ? 'transparent' : 'light'

          return (
            <div>
              <Notifications width={width} />
              <Navbar />

              <div
                className="layout-container"
                style={{
                  paddingTop: barTheme == 'transparent' ? 0 : 66,
                }}
              >
                {React.cloneElement(children, newChildrenProps)}
              </div>
              <Footer mediaType={footerMediaType} />
            </div>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoints: PropTypes.shape({}),
}

const mapSizesToProps = ({ width }) => ({ reactSizesWidth: width })

export default withSizes(mapSizesToProps)(Layout)
