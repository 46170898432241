import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import './IrisTooltip.global.scss'

class IrisTooltip extends React.PureComponent {
  render() {
    const { id, children, iconImage, className } = this.props
    return (
      <span className={`iris-tooltip-container ${className}`}>
        <a data-tip data-for={id}>
          {iconImage}
        </a>
        <ReactTooltip
          id={id}
          place="bottom"
          effect="solid"
          className="ivz-tooltip"
        >
          {children}
        </ReactTooltip>
      </span>
    )
  }
}

IrisTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  iconImage: PropTypes.shape().isRequired,
}

export default IrisTooltip
