const BannerKey = 'banners'

const getCache = () =>
  localStorage.getItem(BannerKey)
    ? JSON.parse(localStorage.getItem(BannerKey))
    : {}

const clear = () => localStorage.removeItem(BannerKey)

export const contains = id => id in getCache()

export const set = id => {
  const cache = getCache()
  const updated = JSON.stringify({ ...cache, [id]: 1 })
  localStorage.setItem(BannerKey, updated)
}
