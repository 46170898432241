import React from 'react'
import Modal from 'react-modal'
import './ModalStyles.global.scss'

Modal.setAppElement(`#___gatsby`)

export default ({
  className,
  children,
  isOpen,
  handleClose,
  closeIconClassName,
}) => (
  <Modal
    className={`iris-modal ${className}`}
    overlayClassName="iris-modal-overlay"
    isOpen={isOpen}
    onRequestClose={handleClose}
    contentLabel=""
  >
    <svg
      fill="#616161"
      className={`close-icon ${closeIconClassName}`}
      onClick={handleClose}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
    {children}
  </Modal>
)
