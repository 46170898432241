import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'redux-form-material-ui'

/**
 * React component to wrap redux-form-material-ui Checkbox for use with redux-form, display error
 * Had to make it a class because it needs access to this.props
 */

/* eslint-disable react/prefer-stateless-function */
class IrisCheckbox extends Component {
  render() {
    const { meta } = this.props
    return (
      <div>
        <Checkbox
          {...this.props}
          labelstyle={{ fontFamily: 'irisvr' }}
          iconstyle={{ fill: '#727FE0' }}
          disableRipple
        />
        {meta.touched && meta.error && (
          <div className="error">{meta.error}</div>
        )}
      </div>
    )
  }
}

IrisCheckbox.propTypes = {
  meta: PropTypes.shape({}),
}

export default IrisCheckbox
