import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import DropdownPopper from './DropdownPopper'
import DropdownArrowToggle from './DropdownArrowToggle'

class Dropdown extends React.PureComponent {
  state = {
    dropdownOpen: false,
  }

  handleToggle = () => {
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ dropdownOpen: false })
  }

  render() {
    const { dropdownOpen } = this.state
    const { children, dropdownLabel } = this.props

    return (
      <div className="dropdown">
        <IconButton
          disableRipple
          buttonRef={node => (this.anchorEl = node)}
          aria-owns={dropdownOpen ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
          color="inherit"
          aria-label="Menu"
          className="dropdown-icon"
        >
          <span
            className="dropdown-text"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {dropdownLabel}
            <DropdownArrowToggle open={dropdownOpen} />
          </span>
        </IconButton>
        <DropdownPopper
          anchorEl={this.anchorEl}
          handleClose={this.handleClose}
          handleToggle={this.handleToggle}
          open={dropdownOpen}
        >
          {children}
        </DropdownPopper>
      </div>
    )
  }
}

export default Dropdown
