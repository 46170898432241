import React from 'react'
import PropTypes from 'prop-types'
import content from './logoContent'
import IrisImg from './IrisImg'

/** Stateless view component for 'LogoBanner' sections on landing page and industry pages */
const LogoBanner = ({ largeWidth, images, type, industry, color }) => {
  const logoArr = industry ? content[industry] : content.main
  const sectionClass = industry ? 'featured industry' : 'featured'
  const imgWrapperClass = largeWidth ? 'large' : 'small'

  console.log(images)
  return (
    <section className={`${sectionClass} ${type} ${imgWrapperClass}`}>
      <div className="container">
        <div className="title" style={{ color: '#313131', marginTop: '1.5em' }}>
          {type === 'featured' ? 'Featured On' : 'Trusted By'}
        </div>
        <div className="logo-container">
          {Object.keys(logoArr)
            .map((k, i) => [k, logoArr[k], i])
            .map(([companyName, { linkUrl, imgAlt }, i]) => {
              const file = images[companyName]
              return (
                <a
                  // className="col-lg-2 col-md-2 col-sm-4 col-xs-6"
                  key={i}
                  href={linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={`img-div ${imgWrapperClass}`}>
                    <IrisImg
                      className={`center-vertically ${companyName}`}
                      file={file}
                      alt={imgAlt}
                    />
                  </div>
                </a>
              )
            })}
        </div>
      </div>
    </section>
  )
}

LogoBanner.propTypes = {
  industry: PropTypes.string,
  type: PropTypes.string,
}

export default LogoBanner
